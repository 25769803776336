import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Menu from "../components/global/Menu"
import ReactMarkdown from "react-markdown"
import Footer from "../components/global/Footer"

const PrivacyPolicyPage = ({ data: { strapiPrivacyPolicyPage } }) => (
  <Layout bodyTheme="theme-books">
    <header>
      <Menu />
    </header>
    <main className="page-wrapper">
      <div className="section-heading-text">
        <section className="section section-first">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-2">
                  <div className="section-heading-text">
                    <ReactMarkdown
                      source={strapiPrivacyPolicyPage["Title"] || "N/A"}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="consulting-content-text privacy-policy-text">
                  <ReactMarkdown
                    source={strapiPrivacyPolicyPage["PrivacyPolicy"] || "N/A"}
                  />
                  <ReactMarkdown
                    source={
                      strapiPrivacyPolicyPage["InformationWeCollect"] || "N/A"
                    }
                  />
                  <ReactMarkdown
                    source={
                      strapiPrivacyPolicyPage["InformationCollected"] || "N/A"
                    }
                  />
                  <ReactMarkdown
                    source={strapiPrivacyPolicyPage["HowToOptOut"] || "N/A"}
                  />
                  <ReactMarkdown
                    source={
                      strapiPrivacyPolicyPage["HowInformationIsUsed"] || "N/A"
                    }
                  />
                  <ReactMarkdown
                    source={
                      strapiPrivacyPolicyPage["AccessingAndModifying"] || "N/A"
                    }
                  />
                  <ReactMarkdown
                    source={strapiPrivacyPolicyPage["HowWeProtect"] || "N/A"}
                  />
                  <ReactMarkdown
                    source={
                      strapiPrivacyPolicyPage["ImportantNotices"] || "N/A"
                    }
                  />
                  <ReactMarkdown
                    source={strapiPrivacyPolicyPage["AppStores"] || "N/A"}
                  />
                  <ReactMarkdown
                    source={strapiPrivacyPolicyPage["Children"] || "N/A"}
                  />
                  <ReactMarkdown
                    source={strapiPrivacyPolicyPage["ChangesToThis"] || "N/A"}
                  />
                  <ReactMarkdown
                    source={strapiPrivacyPolicyPage["HowToContact"] || "N/A"}
                  />
                  <ReactMarkdown
                    source={strapiPrivacyPolicyPage["Jurisdiction"] || "N/A"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </main>
  </Layout>
)

export const query = graphql`
  query PrivacyPolicyPageQuery {
    strapiPrivacyPolicyPage {
      Title
      PrivacyPolicy
      InformationWeCollect
      InformationCollected
      HowToOptOut
      HowInformationIsUsed
      AccessingAndModifying
      HowWeProtect
      ImportantNotices
      AppStores
      Children
      ChangesToThis
      HowToContact
      Jurisdiction
    }
  }
`
export default PrivacyPolicyPage
